export function agent() {
	/* Android端末の場合「body」に「android」のクラスを自動で加える*/
	if (navigator.userAgent.indexOf("Android") > 0) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("android");
	}

	// ブラウザごとにクラス追加
	const agent = window.navigator.userAgent.toLowerCase();

	if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("ie");
	} else if (agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("edge");
	} else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("opera");
	} else if (agent.indexOf("chrome") != -1) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("chrome");
	} else if (agent.indexOf("safari") != -1) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("safari");
	} else if (agent.indexOf("firefox") != -1) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("firefox");
	} else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
		let body = document.getElementsByTagName("body")[0];
		body.classList.add("opera");
	}

}
