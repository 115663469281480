export function drawer() {
	window.addEventListener("DOMContentLoaded", function () {
		// ===================================================
		// ハンバーガーメニュー
		// メニュー展開時に背景を固定
		// ===================================================
		// drawer - original

		let scrollY = 0;

		// メニュー展開時に背景を固定
		const backgroundFix = (bool) => {
			const scrollingElement = () => {
				const browser = window.navigator.userAgent.toLowerCase();
				if ("scrollingElement" in document) return document.scrollingElement;
				return document.documentElement;
			};
			// let scrollY = 0;
			// const scrollY = bool ? scrollingElement().scrollTop : parseInt(document.body.style.top || "0");

			// bool = true → 今のスクロール位置を保持
			// bool = false → 今のbodyの位置（画面固定時にbodyを上に移動した高さ=前回のscrollY)を取得
			scrollY = bool ? scrollingElement().scrollTop : parseInt(document.body.style.top || "0");

			const fixedStyles = {
				height: "100vh",
				position: "fixed",
				top: `${scrollY * -1}px`,
				left: "0",
				width: "100vw",
			};

			// bool = true → fixedStylesを設定して画面固定
			// bool = false → 空文字列を設定して初期値へ戻す
			Object.keys(fixedStyles).forEach((key) => {
				document.body.style[key] = bool ? fixedStyles[key] : "";
			});

			// bool = false ：画面固定解除時にスクロールが初期値に戻ってしまっているので、以前のスクロール位置まで移動
			if (!bool) {
				window.scrollTo(0, scrollY * -1);
			}
		};

		// 変数定義
		const classActive = "is-active";
		const classInactive = "is-inactive";

		// header, bodyへ付与するクラス
		// const maskOn = "-on";

		let flg = false;

		let drawer = document.getElementById("js-drawer");
		let focusTrap = document.getElementById("js-focus-trap");
		let menu = document.querySelector(".js-nav-area");

		let header = document.getElementById("js-header");
		// let contents = document.getElementById("js-contents");
		// let headerMask = document.getElementById("js-headerMask");
		// let contentsMask = document.getElementById("js-contentsMask");

		// メニューを開く
		function openMenu() {
			// アイコン変形、ナビエリアvisible
			if (drawer.classList.contains(classInactive)) {
				// 2回目以降
				drawer.classList.replace(classInactive, classActive);
			} else {
				// 初回ドロワーボタン動作時
				drawer.classList.add(classActive);
			}

			menu.classList.add(classActive);

			// 背面コンテンツ固定
			backgroundFix(true);
			drawer.setAttribute("aria-expanded", "true");
			flg = true;
		}

		// ハンバーガーメニューを閉じる
		function closeMenu() {
			// アイコン変形、ナビエリアhidden
			drawer.classList.replace(classActive, classInactive);
			// drawer.classList.add("is-inactive");
			// drawer.classList.remove(classActive);

			menu.classList.remove(classActive);

			// 背面コンテンツ固定解除
			backgroundFix(false);

			// ドロワー内のコンテンツをスクロールトップに戻す(スクロールあり用)
			// const menu = document.querySelector(".js-nav-area");
			menu.scrollTop = 0;

			drawer.setAttribute("aria-expanded", "false");
			drawer.focus();
			flg = false;
		}

		// ハンバーガーメニュー開閉制御
		drawer.addEventListener("click", (e) => {
			// ハンバーガーボタンが選択されたら

			if (flg) {
				// flgの状態で制御内容を切り替え
				// ハンバーガーメニューを閉じる
				closeMenu();
			} else {
				// ハンバーガーメニューを開く
				openMenu();
			}
		});

		window.addEventListener("keydown", () => {
			//escキー押下でメニューを閉じられるように
			if (event.key === "Escape") {
				closeMenu();
			}
		});

		// フォーカストラップ制御
		focusTrap.addEventListener("focus", (e) => {
			drawer.focus();
		});

		// ドロワーが開いているときに画面幅がPC幅になったとき
		const widthOverTabS = 768;

		// window.addEventListener("resize", function () {
		// 	if (window.innerWidth > widthOverTabS) {
		// 		// console.log("メニューとじます");

		// 		closeMenu();
		// 		// backgroundFix(false);
		// 	}
		// });
		window.addEventListener("resize", function () {
			if (window.innerWidth > widthOverTabS && drawer.classList.contains("is-active")) {
				// console.log("メニューとじます");

				closeMenu();
				// backgroundFix(false);
			}
		});

		// ===================================================
		// smoothscroll, toTop

		// a href が # で始まっている要素を取得
		let anchorTrigger = document.querySelectorAll('a[href^="#"]');
		// ヘッダーの高さを変数に格納
		let headerHeight = document.querySelector(".header").clientHeight;
		let toTop = document.querySelector(".js-toTop");
		// console.log(headerHeight);

		anchorTrigger.forEach((el) => {
			el.addEventListener("click", function (e) {
				e.preventDefault();
				let href = el.getAttribute("href");
				let target = document.getElementById(href.replace("#", ""));
				let rect = target.getBoundingClientRect().top;
				let offset = window.scrollY;
				// console.log(offset);

				//ドロワーメニューor普通のリンク
				if (el.classList.contains("js-drawerLink")) {
					// ドロワーメニューの場合
					// スクロールする距離を再建
					// 背面固定メソッドで記憶した位置（scrollY）を使用する
					// console.log(headerHeight);
					let locate = rect + scrollY - headerHeight; // 画面上端から移動先までの距離
					// console.log(headerHeight);
					// ドロワーメニューを閉じる
					closeMenu();

					window.scrollTo({
						top: locate,
						behavior: "smooth",
					});
				} else {
					// elがクラス「js-drawerLink」を持っていない場合
					// スクロールする距離を再建
					let locate = rect + offset - headerHeight; // 画面上端から移動先までの距離

					// スクロールさせる
					window.scrollTo({
						top: locate,
						behavior: "smooth",
					});
				}
			});
		});

		// スクロールが100pxを超えたらtoTopボタンを表示
		this.window.addEventListener("scroll", function () {
			// this.window.pageXOffset 現在の位置を取得
			if (this.window.pageYOffset > 100) {
				toTop.classList.add("is-visible");
			} else {
				toTop.classList.remove("is-visible");
			}
		});

		// 上へ戻るボタン
		toTop.addEventListener("click", function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		});
	});
}
